import React from "react";
import '../assets/css/templates/landing-template.css';
import { Helmet } from "react-helmet";
import { ContextWithLandingItemProps } from "../types/landingItem";
import { Link, PageProps } from "gatsby";
import ReactGA from 'react-ga';

const LandingTemplate: React.FC<ContextWithLandingItemProps & PageProps> = ({ 
  pageContext: { id, name, iconName, screenshotName, description, privacyPolicyId, iosLink, androidLink},
  location
}) => {
  
    React.useEffect(() => {
  		ReactGA.initialize('UA-88504612-1');
  		ReactGA.pageview(`/landing/${id}`);
  	}, []);
    
    // Handle automatic redirection based on destination parameter
    React.useEffect(() => {
      // Only run in browser environment
      if (typeof window !== 'undefined') {
        const params = new URLSearchParams(location.search);
        const destination = params.get('destination');
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

        if (destination === 'ios' && iosLink) {
          window.location.replace(iosLink);
        } else if (destination === 'android' && androidLink) {
          window.location.replace(androidLink);
        } else if (destination === 'automatic') {
          // Check for iOS devices
          if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream && iosLink) {
            window.location.replace(iosLink);
          // Check for Android devices
          } else if (/android/i.test(userAgent) && androidLink) {
            window.location.replace(androidLink);
          }
          // If neither iOS nor Android (or no link available), do nothing and stay on the page.
        }
      }
    }, [location, iosLink, androidLink]);
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{name}</title>
        <body className="landing-page-body" />
      </Helmet>
      <div className="landing-overflow"></div>
      <div className="landing">
       <div className="container">
          <div className="responsive intro-container">
            <div style={{textAlign: "center", width:"100%"}} className="app-icon center-mobile-version">
              <img className="landing-img" style={{width:"90px"}} alt="App icon" src={require(`../assets/images/${iconName}`).default} />
              <div style={{fontWeight:"200", width:"100%", fontSize:"35px", marginTop: "7px", marginLeft: "2px"}}>{name}</div>
          </div>
          <div className="intro">
            <div style={{fontWeight:"200", width:"100%", fontSize:"28px", color:"#FFFFFF", marginBottom:"20px", lineHeight:"1.2"}}>{description}</div>
          </div>
          <div className="download-buttons-container">
            {iosLink && (
              <a className="primary-btn app-store-btn" href={iosLink}>Get it on the App Store</a>
            )}
            {androidLink && (
              <a className="primary-btn play-store-btn" href={androidLink}>Get it on the Play Store</a>
            )}
          </div>
        </div>
        <div style={{height: "auto"}} className="responsive iphone">
          <div className="iphone-screenshot">
            <img className="landing-img" src={require(`../assets/images/${screenshotName}`).default} alt="App screenshot" />
          </div>
          <img className="landing-img iphone-mask" src={require(`../assets/images/iphone.png`).default} />
        </div>
      </div>
      <div className="container landing-footer">
        <div className="sep"></div>
        <p className="responsive credit"></p>
        <div className="responsive contact" style={{textAlign: "right"}}>
          <Link to={`mailto:office@pixelworkssoftware.com?subject=${name}`}>Contact</Link>
          <Link to={`/${privacyPolicyId}`}>Privacy</Link> 
        </div>
      </div>
      </div>
    </React.Fragment>
  );
}

export default LandingTemplate;
